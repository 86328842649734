import { makeAutoObservable } from "mobx";
import { Pair } from "src/api/dexScreener";
import { IDisposable } from "src/helpers/utils";
import { IObservableCache } from "src/state/shared/Cache";
import { TradePairQuotesCalculator } from "src/state/shared/DEX/providers/TradePairQuotesCalculator";
import { ITradePairQuotesProvider } from "src/state/shared/DEX/providers/TradePairQuotesCalculator/types";
import {
  ITradePairPriceProvider,
  TradePairPriceProvider,
} from "src/state/shared/DEX/providers/TradePairUSDPriceProvider";
import {
  IBotTickersProvider,
  IScreenerNetworkProvider,
  ISwapPairAddressProvider,
  ITradePairProvider,
} from "src/state/shared/DEX/providers/types";
import { DEXNETradePairProviderStore } from "./TradePairProvider";

interface IDEXNEBotContextParams {
  networkProvider: IScreenerNetworkProvider;
  pairAddressProvider: ISwapPairAddressProvider;
  pairCacheStore: IObservableCache<Pair>;
  tickersProvider: IBotTickersProvider;
}

export interface IDEXNEBotContextProvider {
  tradePairProvider: ITradePairProvider;
  tradePairPriceProvider: ITradePairPriceProvider;
  tradePairQuotesProvider: ITradePairQuotesProvider;
}

export class DEXNEBotContextProvider implements IDEXNEBotContextProvider, IDisposable {
  private _tradePairProvider: ITradePairProvider;

  private _tradePairPriceProvider: ITradePairPriceProvider;

  private _tradePairQuotesProvider: ITradePairQuotesProvider;

  constructor({
    networkProvider,
    pairAddressProvider,
    pairCacheStore,
    tickersProvider,
  }: IDEXNEBotContextParams) {
    makeAutoObservable(this);

    this._tradePairProvider = new DEXNETradePairProviderStore({
      tickersProvider,
    });

    this._tradePairPriceProvider = new TradePairPriceProvider({
      tradePairProvider: this._tradePairProvider,
      pairAddressProvider,
      networkProvider,
      pairCacheStore,
    });

    this._tradePairQuotesProvider = new TradePairQuotesCalculator({
      tradePairProvider: this._tradePairProvider,
      tradePairPriceProvider: this._tradePairPriceProvider,
    });
  }

  get tradePairProvider() {
    return this._tradePairProvider;
  }

  get tradePairPriceProvider() {
    return this._tradePairPriceProvider;
  }

  get tradePairQuotesProvider() {
    return this._tradePairQuotesProvider;
  }

  destroy = () => {
    this._tradePairProvider.destroy();
  };
}
