import { Token, TradeType } from "@uniswap/sdk-core";
import { makeAutoObservable } from "mobx";
import { computedFn } from "mobx-utils";
import { makeLoggable } from "src/helpers/logger";
import { TradePair } from "../entities/TradePair";

export enum Field {
  INPUT = "INPUT",
  OUTPUT = "OUTPUT",
}

export interface SwapState {
  type: TradeType;
  amount: string;
  [Field.INPUT]?: Token;
  [Field.OUTPUT]?: Token;
}

export const INITIAL_SWAP_STATE: SwapState = {
  type: TradeType.EXACT_INPUT,
  amount: "",
};

export function isExactInput(tradeType: TradeType): boolean {
  return tradeType === TradeType.EXACT_INPUT;
}

export function invertTradeType(tradeType: TradeType): TradeType {
  switch (tradeType) {
    case TradeType.EXACT_INPUT:
      return TradeType.EXACT_OUTPUT;
    case TradeType.EXACT_OUTPUT:
      return TradeType.EXACT_INPUT;
  }
}

export function toTradeType(modifiedField: Field): TradeType {
  switch (modifiedField) {
    case Field.INPUT:
      return TradeType.EXACT_INPUT;
    case Field.OUTPUT:
      return TradeType.EXACT_OUTPUT;
  }
}

export interface ISwapState {
  get swap(): SwapState;
  getAmount: (field: Field) => string | undefined;
  isSwapFieldIndependent: (field: Field) => boolean;
  onAmountChange: (field: Field) => (newAmount: string) => void;
  getToken: (field: Field) => Token | undefined;
  setSwapTokens: (tradePair: TradePair) => void;
  switchSwapTokens: () => void;
}

export class SwapStateStore implements ISwapState {
  private _swap: SwapState = INITIAL_SWAP_STATE;

  constructor() {
    makeAutoObservable(this);

    makeLoggable(this, { swap: true, getAmount: () => this.swap.amount });
  }

  get swap() {
    return this._swap;
  }

  getAmount = computedFn((field: Field) => {
    const isFieldIndependent = this.isSwapFieldIndependent(field);
    return isFieldIndependent ? this._swap.amount : undefined;
  });

  isSwapFieldIndependent = computedFn((field: Field) => {
    const { type } = this._swap;
    return type === toTradeType(field);
  });

  private _updateAmount = (field: Field, newAmount: string) => {
    this._swap.type = toTradeType(field);
    this._swap.amount = newAmount;
  };

  onAmountChange = (field: Field) => (newAmount: string) => {
    this._updateAmount(field, newAmount);
  };

  getToken = computedFn((field: Field) => this._swap[field]);

  setSwapTokens = ({ quote: quoteToken, base: baseToken }: TradePair) => {
    this._swap.type = TradeType.EXACT_INPUT;
    this._swap[Field.OUTPUT] = baseToken;
    this._swap[Field.INPUT] = quoteToken;
  };

  switchSwapTokens = () => {
    this._swap.type = invertTradeType(this._swap.type);
    const oldOutput = this._swap[Field.OUTPUT];
    this._swap[Field.OUTPUT] = this._swap[Field.INPUT];
    this._swap[Field.INPUT] = oldOutput;
  };
}
