import { ComponentPropsWithoutRef, memo } from "react";
import { separation } from "src/helpers/separation";
import * as styles from "./style";

interface SeparatorNumProps extends ComponentPropsWithoutRef<"span"> {
  value: string | number;
}

export const SeparatorNum = memo(({ value, ...props }: SeparatorNumProps) => (
  <styles.Wrapper {...props}>{separation(value)}</styles.Wrapper>
));
