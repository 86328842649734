import { observer } from "mobx-react-lite";
import { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { PermissionWrapper } from "src/components/shared/PermissionWrapper";
import { EmptySuspenseFallBack } from "src/components/shared/shared";
import { AllBotsProvider } from "src/context/AllBots";
import { CreateBotProvider } from "src/context/CEX/CreateBotProvider";
import { DEXV2BotProviders } from "src/context/DEXV2/DEXV2BotProviders";
import { DEXNECreateContext } from "src/context/DEX_NE/Create";
import { DEXNEBotProvider } from "src/context/DEX_NE/bot";
import { UserManagerProvider } from "src/context/UserManager/UserManager";
import { lazily } from "src/helpers/lazily";
import { useAppState } from "src/state";
import { getRoutePath } from "../components/NavigationMenu/MenuContent/shared/MenuList/utils";
import { ErrorBoundary } from "../components/shared/ErrorBoundary";
import { ExchangeAccountingContext } from "../context/CEX/ExchangeAccounting";
import { TeamsProvider } from "../context/UserManager/Groups";
import { AuthRoutes, DEXV2Ability, MainPagesAbilities, PARTY_ROUTE } from "./constants";
import { LoginRedirect } from "./redirects/LoginRedirect";
import { MainRedirect } from "./redirects/MainRedirect";
import { PartyRouter } from "./routers/PartyRouter";
import { useStoryRouter } from "./routers/StoryRouter";
import * as styles from "./style";

const { AllBots } = lazily(() => import("../components/AllBots"));
const { CEXContent } = lazily(() => import("../components/BotsContent/CEX"));
const { DEXContent } = lazily(() => import("../components/BotsContent/DEX"));
const { DEXV2Content } = lazily(() => import("../components/BotsContent/DEX_V2"));
const { DEXNEContent } = lazily(() => import("../components/BotsContent/DEX_NE"));
const { Access } = lazily(() => import("../components/UserManagement/Access"));
const { Teams } = lazily(() => import("../components/UserManagement/Teams"));
const { CEXBotCreating } = lazily(() => import("../components/BotCreating/CEX"));
const { DEXBotCreating } = lazily(() => import("../components/BotCreating/DEX"));
const { DEXNEBotCreating } = lazily(() => import("../components/BotCreating/DEX_NE"));
const { ExchangeAccounting } = lazily(() => import("../components/ExchangeAccounting"));
const { Login } = lazily(() => import("../components/Auth/Login"));
const { Confirm } = lazily(() => import("../components/Auth/Confirm"));
const { MarketCheck } = lazily(() => import("../components/MarketCheck"));

export const AppRouter = observer(() => {
  const { userState } = useAppState();

  const StoryRouter = useStoryRouter();

  return (
    <Suspense fallback={<EmptySuspenseFallBack />}>
      {userState.isLoggedIn ? (
        <Switch>
          <Route path={getRoutePath({ layer: "main", params: { section: "MainPage" } })}>
            <Redirect to={getRoutePath({ layer: "main", params: { section: "AllBotsPage" } })} />
          </Route>

          <Route path={getRoutePath({ layer: "main", params: { section: "AllBotsPage" } })}>
            <ErrorBoundary fallback={<styles.PageFallback />}>
              <AllBotsProvider>
                <AllBots />
              </AllBotsProvider>
            </ErrorBoundary>
          </Route>

          <Route
            path={getRoutePath({ layer: "main", params: { section: "MarketCheckPage" } })}
            exact
          >
            <PermissionWrapper abilityName={MainPagesAbilities.MarketCheckView} showInfoMsg>
              <ErrorBoundary fallback={<styles.PageFallback />}>
                <MarketCheck />
              </ErrorBoundary>
            </PermissionWrapper>
          </Route>

          <Route path={getRoutePath({ layer: "main", params: { section: "AccessPage" } })}>
            <PermissionWrapper abilityName={MainPagesAbilities.AccessView} showInfoMsg>
              <ErrorBoundary fallback={<styles.PageFallback />}>
                <UserManagerProvider>
                  <Access />
                </UserManagerProvider>
              </ErrorBoundary>
            </PermissionWrapper>
          </Route>

          <Route path={getRoutePath({ layer: "main", params: { section: "TeamsPage" } })}>
            <PermissionWrapper abilityName={MainPagesAbilities.TeamsView} showInfoMsg>
              <TeamsProvider>
                <ErrorBoundary fallback={<styles.PageFallback />}>
                  <Teams />
                </ErrorBoundary>
              </TeamsProvider>
            </PermissionWrapper>
          </Route>

          <Route path={getRoutePath({ layer: "main", params: { section: "CEXCreatingPage" } })}>
            <PermissionWrapper abilityName={MainPagesAbilities.CEXCreatingView} showInfoMsg>
              <ErrorBoundary fallback={<styles.PageFallback />}>
                <CreateBotProvider>
                  <CEXBotCreating />
                </CreateBotProvider>
              </ErrorBoundary>
            </PermissionWrapper>
          </Route>

          <Route
            path={`${getRoutePath({
              layer: "main",
              params: { section: "DEXCreatingPage" },
            })}/:uuid?/`}
          >
            <PermissionWrapper abilityName={DEXV2Ability.BotManage} showInfoMsg>
              <ErrorBoundary fallback={<styles.PageFallback />}>
                <DEXBotCreating />
              </ErrorBoundary>
            </PermissionWrapper>
          </Route>

          <Route path={getRoutePath({ layer: "main", params: { section: "DEXNECreatingPage" } })}>
            <PermissionWrapper abilityName={MainPagesAbilities.DEXNECreatingView} showInfoMsg>
              <ErrorBoundary fallback={<styles.PageFallback />}>
                <DEXNECreateContext.Provider>
                  <DEXNEBotCreating />
                </DEXNECreateContext.Provider>
              </ErrorBoundary>
            </PermissionWrapper>
          </Route>

          <Route path={getRoutePath({ layer: "main", params: { section: "AccountingPage" } })}>
            <ErrorBoundary>
              <PermissionWrapper abilityName={MainPagesAbilities.AccountingView} showInfoMsg>
                <ExchangeAccountingContext.Provider>
                  <ExchangeAccounting />
                </ExchangeAccountingContext.Provider>
              </PermissionWrapper>
            </ErrorBoundary>
          </Route>

          <Route path={PARTY_ROUTE}>
            <PartyRouter />

            <Route path={getRoutePath({ layer: "bot", params: { botType: "CEX" } })}>
              <CEXContent />
            </Route>

            <Route path={getRoutePath({ layer: "bot", params: { botType: "DEX" } })}>
              <DEXContent />
            </Route>

            <Route path={getRoutePath({ layer: "bot", params: { botType: "DEX_V2" } })}>
              <ErrorBoundary fallback={<styles.PageFallback />}>
                <DEXV2BotProviders>
                  <DEXV2Content />
                </DEXV2BotProviders>
              </ErrorBoundary>
            </Route>

            <Route path={getRoutePath({ layer: "bot", params: { botType: "DEX_NE" } })}>
              <ErrorBoundary>
                <DEXNEBotProvider>
                  <DEXNEContent />
                </DEXNEBotProvider>
              </ErrorBoundary>
            </Route>
          </Route>

          {StoryRouter}

          <Route
            path={getRoutePath({ layer: "main", params: { section: "Root" } })}
            render={MainRedirect}
          />
        </Switch>
      ) : (
        <Switch>
          <Route path={AuthRoutes.LoginPage}>
            <Login />
          </Route>
          <Route path={`${AuthRoutes.ConfirmPage}/:login/`}>
            <Confirm />
          </Route>

          {StoryRouter}

          <Route
            path={getRoutePath({ layer: "main", params: { section: "Root" } })}
            render={LoginRedirect}
          />
        </Switch>
      )}
    </Suspense>
  );
});
