import { IReactionDisposer, makeAutoObservable, reaction } from "mobx";
import { DateTimeRange } from "src/components/shared/DatePickers/shared/models/dateTimeRange";
import { showSuccessMsg } from "src/helpers/message";
import { roundSingleValue } from "src/helpers/rounding";
import { RangePickerStore } from "src/state/shared/RangePicker";
import { UrlSearchParamsStore } from "src/state/shared/UrlSearchParams";
import { IQueryHistory } from "src/state/shared/UrlSearchParams/types";
import { getDateTimeRangeFromUtc } from "src/helpers/dateUtils";
import { DEFAULT_FUNDING_RANGE, FundingStore, getDefaultFundingRefreshRange } from "..";
import {
  AggregatedFunding,
  AutoFunding,
  FundingMenuValue,
  ManualFunding,
  PartyFundingRange,
  TransfersFunding,
  UpdateFunding,
} from "../types";
import { AggregatedFundingStore } from "./AggregatedFundingStore";
import { AutoFundingStore } from "./AutoFundingStore";
import { ManualFundingStore } from "./ManualFundingStore";
import { TransfersFundingStore } from "./TransfersFundingStore";
import { UrlParams, searchParamsValidationSchema } from "./validationSchemes";

export class FundingManagementStore {
  aggregatedFundingStore: AggregatedFundingStore;

  transfersFundingStore: TransfersFundingStore;

  manualFundingStore: ManualFundingStore;

  autoFundingStore: AutoFundingStore;

  private _activeFundingMenu: FundingMenuValue = FundingMenuValue.AGGREGATED;

  private _isLoading = false;

  mainState: FundingStore;

  rangeState: RangePickerStore;

  private _urlSearchParamsState: UrlSearchParamsStore<UrlParams>;

  private _querySearchParamsReaction?: IReactionDisposer;

  constructor(state: FundingStore, searchParamsProps: IQueryHistory) {
    this.aggregatedFundingStore = new AggregatedFundingStore();
    this.transfersFundingStore = new TransfersFundingStore();
    this.manualFundingStore = new ManualFundingStore();
    this.autoFundingStore = new AutoFundingStore();

    this.mainState = state;
    this.mainState.setUpdHandlers("updFundingList", this.updData);

    this.rangeState = new RangePickerStore(
      this,
      DEFAULT_FUNDING_RANGE,
      getDefaultFundingRefreshRange
    );

    this._urlSearchParamsState = new UrlSearchParamsStore(this, {
      ...searchParamsProps,
      validationSchema: searchParamsValidationSchema,
    });

    makeAutoObservable(this);
  }

  get activeFundingMenu() {
    return this._activeFundingMenu;
  }

  set activeFundingMenu(activeList: FundingMenuValue) {
    this._activeFundingMenu = activeList;
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(loading: boolean) {
    this._isLoading = loading;
  }

  get party() {
    return this.mainState.party;
  }

  get tableData() {
    return this.currentFundingStore.list.map((el) => ({
      ...el,
      amount: roundSingleValue(Number(el.amount)),
    }));
  }

  private get currentFundingStore() {
    const store = {
      AGGREGATED: this.aggregatedFundingStore,
      TRANSFERS: this.transfersFundingStore,
      MANUAL: this.manualFundingStore,
      AUTO: this.autoFundingStore,
    };

    return store[this.activeFundingMenu];
  }

  private get _querySearchParams(): UrlParams {
    return { from: this.rangeState.start, to: this.rangeState.end };
  }

  loadData = () => {
    if (this.rangeState.start && this.rangeState.end)
      this._getFundings({
        party: this.mainState.party,
        from: this.rangeState.start,
        to: this.rangeState.end,
      });
  };

  updData = () => {
    this.loadData();
  };

  setRange = (period: DateTimeRange) => this.rangeState.setRange(period);

  getSelectFunding = (id: number) => this.currentFundingStore.getSelectFunding(id);

  toggleFundingIgnore = (id: number, isIgnore: boolean) =>
    this.updateFunding(id, { skip: isIgnore });

  updateFunding = async (id: number, data: UpdateFunding, successCb?: () => void) => {
    this.isLoading = true;

    try {
      const resp = await this.currentFundingStore.updateFunding(id, data);

      if (resp && !resp.isError) {
        showSuccessMsg("Funding record successfully updated");

        successCb?.();
        this.mainState.updAllData();
      }
    } finally {
      this.isLoading = false;
    }
  };

  deleteFunding = (id: number) => {
    const deleteAction = async () => {
      this.isLoading = true;

      try {
        const resp = await this.currentFundingStore.deleteFundingAction(id);

        if (resp && !resp.isError) {
          showSuccessMsg("Funding record successfully deleted");

          this.mainState.updAllData();
        }
      } finally {
        this.isLoading = false;
      }
    };

    this.currentFundingStore.deleteFundingModal(id, deleteAction);
  };

  setInitialQueries = (queryObj: Partial<UrlParams>) => {
    const { from, to } = queryObj;

    if (from && to) this.rangeState.setStateRange(getDateTimeRangeFromUtc(from, to));
  };

  private _setActiveFundingList = (
    data: (AggregatedFunding | TransfersFunding | ManualFunding | AutoFunding)[]
  ) => {
    this.currentFundingStore.list = data;
  };

  private _getFundings = async (fundingParam: PartyFundingRange) => {
    this.isLoading = true;

    try {
      const { isError, data } = await this.currentFundingStore.loadFunding(fundingParam);

      if (!isError) this._setActiveFundingList(data);
      else this._setActiveFundingList([]);
    } catch {
      this._setActiveFundingList([]);
    } finally {
      this.isLoading = false;
    }
  };

  destroy = () => {};

  subscribe = () => {
    this._querySearchParamsReaction = reaction(
      () => this._querySearchParams,
      (query) => this._urlSearchParamsState.setAllQueryUrl(query),
      { fireImmediately: true }
    );
  };

  unsubscribe = () => this._querySearchParamsReaction?.();
}
