import { Dayjs } from "dayjs";
import { makeAutoObservable } from "mobx";
import ReconnectingWebSocket, { Options } from "reconnecting-websocket";
import { ConnectStatusKeys } from "src/components/shared/Indicators/StatusConnect";
import { toast } from "src/components/shared/Toaster";
import { wsUrl } from "src/environment/env";
import { refreshToken } from "src/helpers/auth";
import { getCurrentDayjs, getDayjsFromMs } from "src/helpers/dateUtils";
import { getToken } from "src/helpers/getToken";
import { logError } from "src/helpers/network/logger";
import { toParseJSON, toStringifyJSON } from "src/helpers/string";
import { getRequestUrl } from "src/helpers/url";
import { OmitUnion } from "src/helpers/utils";
import { DistributeWSresponse, ResponseData } from "src/modules/network/websocket";

export const WS_PREFIX = "ws";

export type SubscriptionType = "subscribe" | "unsubscribe";

export interface IStreamConfig {
  type: SubscriptionType;
  payload: {
    exchange: string;
    pair: string;
  };
}

export interface WSParamsProps {
  url: string;
  config?: IStreamConfig;
  baseUrl?: string;
  wsPrefix?: string;
}

type actionsCb = () => void;

interface WSOptions {
  isShowToast?: boolean;
}

export interface ConstructorProps {
  subCb?: actionsCb[];
  unSubCb?: actionsCb[];
  options?: WSOptions;
}

export interface PingRequestData {
  type: "ping";
  payload: {};
  token: string;
}

export interface PongResponseData {
  time: number;
  tokenExpiredSoon: boolean;
}

export type ResDataWithoutError = OmitUnion<ResponseData, "error">;

type ResponseTime = Dayjs | null;
type ResponseDelay = number | null;

const PING_INTERVAL = 5000;

const RECONNECTING_WS_OPTIONS: Options = {
  minReconnectionDelay: 5000,
  debug: false,
};

const DEFAULT_OPTIONS: WSOptions = {
  isShowToast: true,
};

class WebSocketStore {
  private _data: ResDataWithoutError | null = null;

  private _socket: ReconnectingWebSocket | null = null;

  private _intervalHandler?: ReturnType<typeof setInterval>;

  private _pongTimeoutAwait?: ReturnType<typeof setTimeout>;

  private _firstSendPing = true;

  private _needToken = true;

  private _pingSendTime: ResponseTime = null;

  private _pongReceivingTime: ResponseTime = null;

  private _tsExchange: ResponseTime = null;

  private _tsGateway: ResponseTime = null;

  private _sendDelay: ResponseDelay = null;

  private _exchangeDelay: ResponseDelay = null;

  private _lastConfig: IStreamConfig | null = null;

  subscribeCb: actionsCb[] = [];

  unSubscribeCb: actionsCb[] = [];

  private _wsOption: WSOptions = DEFAULT_OPTIONS;

  constructor({ subCb, unSubCb, options }: ConstructorProps) {
    if (subCb) this.subscribeCb = subCb;
    if (unSubCb) this.unSubscribeCb = unSubCb;
    if (options) this._wsOption = { ...this._wsOption, ...options };

    makeAutoObservable(this);
  }

  private get _pingData(): PingRequestData {
    return {
      type: "ping",
      payload: {},
      // token: this._needToken ? getToken() : "",
      token: getToken(),
    };
  }

  get data() {
    return this._data;
  }

  get socketStatus() {
    return this._socket?.readyState;
  }

  get isOpened() {
    return this._socket?.readyState === 1;
  }

  get needToken() {
    return this._needToken;
  }

  get sendDelay() {
    return this._sendDelay;
  }

  get exchangeDelay() {
    return this._exchangeDelay;
  }

  get sendDelayTitle() {
    return this.getDelayTitle(this._sendDelay, "ping");
  }

  get exchangeDelayTitle() {
    return this.getDelayTitle(this._exchangeDelay, "exchange");
  }

  get statusConnection(): ConnectStatusKeys {
    if (!this.sendDelay) return "noConnection";

    if (this.sendDelay > 150) {
      return "bad";
    }
    if (this.sendDelay > 80 && this.sendDelay <= 150) {
      return "normal";
    }
    if (this.sendDelay > 50 && this.sendDelay <= 80) {
      return "good";
    }
    if (this.sendDelay <= 50) {
      return "great";
    }

    return "noConnection";
  }

  get exchStatusConnection(): ConnectStatusKeys {
    if (!this.exchangeDelay) return "noConnection";

    if (this.exchangeDelay > 225) {
      return "bad";
    }
    if (this.exchangeDelay > 120 && this.exchangeDelay <= 225) {
      return "normal";
    }
    if (this.exchangeDelay > 75 && this.exchangeDelay <= 120) {
      return "good";
    }
    if (this.exchangeDelay <= 75) {
      return "great";
    }

    return "noConnection";
  }

  private _setPingSendTime = () => {
    this._pingSendTime = getCurrentDayjs();
  };

  private _setPongReceivingTime = () => {
    this._pongReceivingTime = getCurrentDayjs();
  };

  private _setLastConfig = (config: IStreamConfig) => {
    this._lastConfig = config;
  };

  // calculate delay ping/pong
  private _calcDelayPingPong = () => {
    if (this._pingSendTime && this._pongReceivingTime) {
      this._sendDelay = this._pongReceivingTime.diff(this._pingSendTime, "millisecond");
    }
  };

  // calculate delay exchange response
  private _calcDelayExchange = () => {
    if (this._tsExchange && this._tsGateway) {
      this._exchangeDelay = this._tsGateway.diff(this._tsExchange, "millisecond");
    }
  };

  private _pingMeterEnable = () => {
    this._intervalHandler = setInterval(this._sendPing, PING_INTERVAL);
  };

  private _sendPing = () => {
    if (!this._socket) return;

    if (this._socket.readyState === WebSocket.OPEN) {
      const requestData = toStringifyJSON(this._pingData);

      this._socket.send(requestData);
      this._setPingSendTime();

      // for enable the timer for receiving pings
      this._firstSendPing = false;
    }
  };

  private _suspendPing = () => {
    clearInterval(this._intervalHandler);
    this._intervalHandler = undefined;
  };

  private _resetSendDelay = () => {
    this._sendDelay = null;
  };

  private _resetExchangeDelay = () => {
    this._exchangeDelay = null;
  };

  // private _setNeedToken = (bool: boolean) => {
  //   this._needToken = bool;
  // };

  private _pongHandler = (pong: PongResponseData) => {
    this._updPongAwaiting();
    this._setPongReceivingTime();

    const tokenExpired = pong.tokenExpiredSoon;

    if (tokenExpired) {
      this._updHandler();
    }
    // else this._setNeedToken(false);

    this._calcDelayPingPong();
  };

  private _updHandler = async () => {
    await refreshToken();

    // this._setNeedToken(true);
  };

  setupWebSocket = ({ url, baseUrl = wsUrl, wsPrefix = WS_PREFIX, config }: WSParamsProps) => {
    const requestUrl = getRequestUrl({
      baseUrl,
      prefix: wsPrefix,
      url,
    });

    this._socket = new ReconnectingWebSocket(requestUrl, undefined, RECONNECTING_WS_OPTIONS);

    this._socket.addEventListener("open", () => {
      // this._setNeedToken(true);

      // start ping/pong meter
      this._pingMeterEnable();

      // send first ping for Authorization
      this._sendPing();

      if (this._firstSendPing) this._updPongAwaiting();

      if (this._lastConfig) {
        // to reconnect the stream again
        this.sendMsgOnStream(this._lastConfig);
      } else if (config) {
        // for the first time open connect stream
        this.sendMsgOnStream(config);
        this._setLastConfig(config);
      }
    });

    this._socket.addEventListener("message", (event) => {
      const newData = toParseJSON<DistributeWSresponse>(event.data);

      if (!newData) return;

      if (newData.error) {
        if (newData.error === "token expired") {
          this._updHandler();
        } else this._throwError(newData.error);

        return;
      }

      if (newData.type === "pong") {
        this._pongHandler(newData.result);
      } else if (newData.type !== "subscribe") {
        this._updateData(newData);

        // perform the necessary actions after receiving data via a websocket connection
        this.subscribeCb.forEach((cb) => cb());
      }
    });

    this._socket.addEventListener("error", (error) => {
      if (error.message) this._throwError(error.message);
    });

    this._socket.addEventListener("close", (event) => {
      this.unSubscribeCb.forEach((cb) => cb());

      // suspend ping after close ws connection
      this._suspendPing();
      this._suspendPongAwaiting();
      this._resetSendDelay();
      this._resetExchangeDelay();
    });
  };

  sendMsgOnStream = (config: IStreamConfig) => {
    if (config.type === "subscribe") this._setLastConfig(config);

    this._socket?.send(toStringifyJSON(config));
  };

  private _updateData = (newData: ResDataWithoutError) => {
    this._delayExchHandler(newData.result.tsExchange, newData.result.tsGateway);

    this._data = newData;
  };

  private _delayExchHandler = (tsExchange: number, tsGateway: number) => {
    this._tsExchange = getDayjsFromMs(tsExchange);
    this._tsGateway = getDayjsFromMs(tsGateway);
    this._calcDelayExchange();
  };

  getDelayTitle = (delay: ResponseDelay, type: "ping" | "exchange") => {
    if (!delay) {
      if (type === "ping") return "No connection";

      return "No data";
    }
    if (type === "exchange") return `Exchange delay ${delay} ms`;
    if (type === "ping") return `Server delay ${delay} ms`;
  };

  closeWebSocket() {
    if (this._socket) this._socket.close();
  }

  private _updPongAwaiting = () => {
    clearTimeout(this._pongTimeoutAwait);

    this._pongTimeoutAwait = setTimeout(this._reconnectWS, 15000);
  };

  private _suspendPongAwaiting = () => {
    clearInterval(this._pongTimeoutAwait);
    this._pongTimeoutAwait = undefined;
  };

  private _reconnectWS = () => {
    if (this._socket) this._socket.reconnect();
  };

  private _throwError = (message: string) => {
    const { isShowToast } = this._wsOption;

    const errorMsg = `Websocket handler
Message: 
${message}`;

    if (isShowToast) {
      toast.error(errorMsg);
    } else {
      logError({ message: errorMsg });
    }
  };
}

export default WebSocketStore;
