import { makeAutoObservable } from "mobx";
import { computedFn } from "mobx-utils";
import {
  addApiKey,
  deleteApiKey,
  getApiKeys,
  getExchangeAccounts,
  updateApiKey,
} from "src/api/bots/CEX/apiKeys";
import { toast } from "src/components/shared/Toaster";
import { showSuccessMsg } from "src/helpers/message";
import { logError } from "src/helpers/network/logger";
import { IDisposable } from "src/helpers/utils";
import {
  AccountApi,
  BaseLiquidityAccountName,
  BotAccountName,
  LiquidityAccountBinding,
  VolumeAccountBinding,
} from "src/modules/accounts";
import { Account } from "src/modules/userManager";
import WindowConsent from "src/state/WindowConsent";
import { ERRORS_MSGS } from "src/validation-schemas";
import AccountsBindings, { filterBaseNames } from "./AccountsBindings";

export const PROHIBITED_BINDING_ACCOUNT = "Not allowed to bind this account";

export interface IApiKeysBotParams {
  uuid: string;
  party: string;
  exchange: string;
}

export const accountToAccountApi = ({ name, uuid }: Account): AccountApi => ({ name, uuid });

export const REQUIRED_LIQUIDITY_ACCOUNTS: BaseLiquidityAccountName[] = ["info"];

export const isRequiredBotAccount = (account: BotAccountName) => {
  const requiredAccounts = REQUIRED_LIQUIDITY_ACCOUNTS;
  return filterBaseNames(account, requiredAccounts, false);
};

export default class CEXApiKeysStore implements IDisposable {
  private _botParams: IApiKeysBotParams = {
    party: "",
    exchange: "",
    uuid: "",
  };

  private _accountsBindings = new AccountsBindings();

  private _loading = false;

  constructor() {
    makeAutoObservable<this, "_getAccountBinding">(this, {
      bindEnabled: false,
      _getAccountBinding: false,
    });
  }

  get bindings(): AccountsBindings {
    return this._accountsBindings;
  }

  get botUUID() {
    return this._botParams.uuid;
  }

  private _getAccountBinding = (name: BotAccountName) =>
    this._accountsBindings.getAccountBinding(name);

  bindEnabled = computedFn((name: BotAccountName) => {
    const binding = this._getAccountBinding(name);

    const account = binding?.account;

    return Boolean(account);
  });

  setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  get loading() {
    return this._loading;
  }

  private _setAccountLoading = (name: BotAccountName, loading: boolean) => {
    const binding = this._getAccountBinding(name);
    binding?.setLoading(loading);
  };

  accountLoading = (name: BotAccountName) => {
    const binding = this._getAccountBinding(name);
    return binding?.loading ?? false;
  };

  private _setAccountError = (name: BotAccountName, error: string) => {
    const binding = this._getAccountBinding(name);
    binding?.setError(error);
  };

  accountError = (name: BotAccountName) => {
    const binding = this._getAccountBinding(name);
    return binding?.error ?? "";
  };

  setBotParameters = (botParams: IApiKeysBotParams) => {
    this._botParams = botParams;
  };

  getData = async () => {
    try {
      this.setLoading(true);

      await Promise.all([
        this._getLiquidityAccountBindings(),
        this._getVolumeAccountBindings(),
        this._getAllAccounts(),
      ]);
    } catch (error) {
      logError(error);
    } finally {
      this.setLoading(false);
    }
  };

  refreshLiquidityBindings = async () => {
    try {
      this.setLoading(true);

      await this._getLiquidityAccountBindings();
    } catch (error) {
      logError(error);
    } finally {
      this.setLoading(false);
    }
  };

  private _getLiquidityAccountBindings = async () => {
    try {
      const { data, isError } = await getApiKeys(this._botParams.uuid, "liquidity");

      if (!isError) {
        const bindings = Object.values(data).flatMap((it) => it as LiquidityAccountBinding[]);

        this._accountsBindings.setLiquidityBindings(bindings);

        return;
      }

      this._accountsBindings.setLiquidityBindings([]);
    } catch {
      this._accountsBindings.setLiquidityBindings([]);
    }
  };

  refreshVolumeBindings = async () => {
    try {
      this.setLoading(true);

      await this._getVolumeAccountBindings();
    } catch (error) {
      logError(error);
    } finally {
      this.setLoading(false);
    }
  };

  private _getVolumeAccountBindings = async () => {
    try {
      const { data, isError } = await getApiKeys(this._botParams.uuid, "volume");

      if (!isError) {
        const bindings = Object.values(data).flatMap((it) => it as VolumeAccountBinding[]);

        this._accountsBindings.setVolumeBindings(bindings);

        return;
      }

      this._accountsBindings.setVolumeBindings([]);
    } catch {
      this._accountsBindings.setVolumeBindings([]);
    }
  };

  private _getAllAccounts = async () => {
    try {
      const { party, exchange } = this._botParams;
      const { data, isError } = await getExchangeAccounts(party, exchange);

      if (!isError) {
        const accounts = data[exchange] ?? [];

        const apiAccounts = accounts.map(accountToAccountApi);

        this._accountsBindings.setExchangeAccounts(apiAccounts);

        return;
      }

      this._accountsBindings.setExchangeAccounts([]);
    } catch {
      this._accountsBindings.setExchangeAccounts([]);
    }
  };

  private _setErrorText = (accountName: BotAccountName, isValid: boolean, error: string) => {
    const errorText = isValid ? "" : error;
    this._setAccountError(accountName, errorText);
  };

  private _validateBinding = (accountName: BotAccountName, accountToBind: AccountApi) => {
    const isValid = this._accountsBindings.validators.BINDING(accountName, accountToBind);

    this._setErrorText(accountName, isValid, PROHIBITED_BINDING_ACCOUNT);

    return isValid;
  };

  private _validateRequired = (accountName: BotAccountName, accountToBind: AccountApi) => {
    const isValid = this._accountsBindings.validators.REQUIRED(accountName, accountToBind);

    this._setErrorText(accountName, isValid, ERRORS_MSGS.isRequired);

    return isValid;
  };

  private _validate = (
    accountName: BotAccountName,
    accountToBind: AccountApi,
    {
      validateBinding = true,
    }: {
      validateBinding?: boolean;
    } = {}
  ) =>
    this._validateRequired(accountName, accountToBind) &&
    (!validateBinding || this._validateBinding(accountName, accountToBind));

  private _bindAccount =
    (uuid: string, accountName: BotAccountName, accountToBind: AccountApi) => async () => {
      try {
        this._setAccountLoading(accountName, true);

        const { isError } = await updateApiKey(this._botParams.uuid, uuid, accountToBind.uuid);

        if (!isError) {
          this._accountsBindings.saveAccountBinding(accountName);

          toast.success(`API Keys for ${accountName.toUpperCase()} saved successfully`);
        }
      } finally {
        this._setAccountLoading(accountName, false);
      }
    };

  bindAccount = (accountName: BotAccountName) => () => {
    const binding = this._getAccountBinding(accountName);

    const accountToBind = binding?.account;

    if (!binding || !accountToBind) return;

    const valid = this._validate(accountName, accountToBind);

    if (!valid) return;

    WindowConsent.showWindow(
      "",
      `Are you sure you want to bind account "${accountToBind.name}" to "${accountName}"?`,
      this._bindAccount(binding.uuid, accountName, accountToBind)
    );
  };

  private _unbindAccount = (accountName: BotAccountName) => async () => {
    try {
      this._setAccountLoading(accountName, true);

      const binding = this._getAccountBinding(accountName);

      if (!binding) return;

      const { isError } = await deleteApiKey(this._botParams.uuid, binding.uuid);

      if (!isError) {
        this._accountsBindings.clearAccountBinding(accountName);

        toast.success(`API Keys for ${accountName.toUpperCase()} deleted successfully`);
      }
    } finally {
      this._setAccountLoading(accountName, false);
    }
  };

  unbindAccount = (accountName: BotAccountName) => () => {
    const binding = this._getAccountBinding(accountName);

    const accountToUnbind = binding?.savedAccount;

    if (!accountToUnbind) return;

    const valid = this._validate(accountName, accountToUnbind, {
      validateBinding: false,
    });

    if (!valid) return;

    WindowConsent.showWindow(
      "",
      `Are you sure you want to unbind account "${accountToUnbind.name}" from "${accountName}"?`,
      this._unbindAccount(accountName)
    );
  };

  addAccount = async (botAccountName: BotAccountName, accountToBind: AccountApi) => {
    const { isError } = await addApiKey(this.botUUID, botAccountName, accountToBind.uuid);
    if (!isError) {
      showSuccessMsg(`new ${botAccountName} account saved successfully!`);
    }
    return isError;
  };

  destroy = () => {};
}
