import { ApiModules, apiUrl } from "src/api";
import {
  CreateDEXV2CounterStrategy,
  CreateDEXV2LimitStrategy,
  DEXV2CounterStrategy,
  DEXV2LimitStrategy,
  DEXV2Settings,
} from "src/state/DEXV2/types";
import ResponseHandler from "src/state/network/ResponseHandler";
import { deployBot } from "./create";
import { StrategiesResponse } from "./types";

export interface GetBotSettingsResponse extends DEXV2Settings {}

export const getBotSettings = async (botId: string) =>
  ResponseHandler.handler<GetBotSettingsResponse>({
    url: apiUrl(ApiModules.DEXV2Bot, `/${botId}/settings`),
    method: "GET",
  });

export interface TurnOnSettingsRequest {
  limit?: boolean;
  volume?: boolean;
  counter?: boolean;
}

export const turnOnSettings = async (botId: string, data: TurnOnSettingsRequest) =>
  ResponseHandler.handler<{}>({
    url: apiUrl(ApiModules.DEXV2Bot, `/${botId}/start`),
    method: "PATCH",
    data,
  });

export interface TurnOffSettingsRequest extends TurnOnSettingsRequest {}

export const turnOffSettings = async (botId: string, data: TurnOffSettingsRequest) =>
  ResponseHandler.handler<{}>({
    url: apiUrl(ApiModules.DEXV2Bot, `/${botId}/stop`),
    method: "PATCH",
    data,
  });

export const turnOffForceSettings = async (botId: string, data: TurnOffSettingsRequest) =>
  ResponseHandler.handler<{}>({
    url: apiUrl(ApiModules.DEXV2Bot, `/${botId}/force/stop`),
    method: "PATCH",
    data,
  });

export type DEXV2UpdateSettings = Pick<
  DEXV2Settings,
  "base_data" | "volume_data" | "limit_data" | "counter_data"
> & {
  base_data: {
    bot_name: string;
  };
};

export interface UpdateSettingsRequest extends Partial<DEXV2UpdateSettings> {}

export const updateBotSettings = async (botId: string, data: UpdateSettingsRequest) =>
  ResponseHandler.handler<UpdateSettingsRequest>({
    url: apiUrl(ApiModules.DEXV2Bot, `/${botId}/settings`),
    method: "PATCH",
    data,
  });

export const updateForceBotSettings = async (botId: string, data: UpdateSettingsRequest) =>
  ResponseHandler.handler<UpdateSettingsRequest>({
    url: apiUrl(ApiModules.DEXV2Bot, `/${botId}/force/settings`),
    method: "PATCH",
    data,
  });

export const stopBot = async (botId: string) =>
  ResponseHandler.handler<{}>({
    url: apiUrl(ApiModules.DEXV2Bot, `/${botId}/cluster/remove`),
    method: "PATCH",
  });

export const startBot = deployBot;

export type GetTransferOracleWalletsResponse = string[];

export const getTransferOracleWallets = async (deployerId: string) =>
  ResponseHandler.handler<GetTransferOracleWalletsResponse>({
    url: apiUrl(ApiModules.DEXV2Bot, `/${deployerId}/oracle/transfer`),
    method: "GET",
  });

export type UpdateTransferOracleWalletsRequest = string[];

export const updateTransferOracleWallets = async (
  deployerId: string,
  data: UpdateTransferOracleWalletsRequest
) =>
  ResponseHandler.handler<{}>({
    url: apiUrl(ApiModules.DEXV2Bot, `/${deployerId}/oracle/transfer`),
    method: "PUT",
    data: { data },
  });

export interface GetSwapOracleWalletsResponse extends GetTransferOracleWalletsResponse {}

export const getSwapOracleWallets = async (deployerId: string) =>
  ResponseHandler.handler<GetSwapOracleWalletsResponse>({
    url: apiUrl(ApiModules.DEXV2Bot, `/${deployerId}/oracle/swap`),
    method: "GET",
  });

export interface UpdateSwapOracleWalletsRequest extends UpdateTransferOracleWalletsRequest {}

export const updateSwapOracleWallets = async (
  deployerId: string,
  data: UpdateSwapOracleWalletsRequest
) =>
  ResponseHandler.handler<{}>({
    url: apiUrl(ApiModules.DEXV2Bot, `/${deployerId}/oracle/swap`),
    method: "PUT",
    data: { data },
  });

export const createBotLimitStrategy = async (botId: string, data: CreateDEXV2LimitStrategy) =>
  ResponseHandler.handler<StrategiesResponse<DEXV2LimitStrategy>>({
    url: apiUrl(ApiModules.DEXV2Bot, `/${botId}/settings/limit`),
    method: "POST",
    data,
  });

export const createBotCounterStrategy = async (botId: string, data: CreateDEXV2CounterStrategy) =>
  ResponseHandler.handler<StrategiesResponse<DEXV2CounterStrategy>>({
    url: apiUrl(ApiModules.DEXV2Bot, `/${botId}/settings/counter`),
    method: "POST",
    data,
  });

export const updateBotLimitStrategy = async (botId: string, { id, ...data }: DEXV2LimitStrategy) =>
  ResponseHandler.handler<StrategiesResponse<DEXV2LimitStrategy>>({
    url: apiUrl(ApiModules.DEXV2Bot, `/${botId}/settings/limit/${id}`),
    method: "POST",
    data,
  });

export const updateBotCounterStrategy = async (
  botId: string,
  { id, ...data }: DEXV2CounterStrategy
) =>
  ResponseHandler.handler<StrategiesResponse<DEXV2CounterStrategy>>({
    url: apiUrl(ApiModules.DEXV2Bot, `/${botId}/settings/counter/${id}`),
    method: "POST",
    data,
  });

export const deleteBotLimitStrategy = async (botId: string, strategyId: string) =>
  ResponseHandler.handler<StrategiesResponse<DEXV2LimitStrategy>>({
    url: apiUrl(ApiModules.DEXV2Bot, `/${botId}/settings/limit/${strategyId}`),
    method: "DELETE",
  });

export const deleteBotCounterStrategy = async (botId: string, strategyId: string) =>
  ResponseHandler.handler<StrategiesResponse<DEXV2CounterStrategy>>({
    url: apiUrl(ApiModules.DEXV2Bot, `/${botId}/settings/counter/${strategyId}`),
    method: "DELETE",
  });
